.section-content,
header-hero::part(container) {
  width: 980px;
  margin-left: auto;
  margin-right: auto;
}

header-hero::part(container) {
  padding-block: 6rem;
}

header-hero {
  --header-padding: 0px;
  --header-background: red; /*transparent;*/
}

header-hero::part(app-icon) {
  /* margin-top: 6rem; */
  margin-bottom: 1.5rem;
}

 

@media only screen and (min-width: 1068px) {
  header-hero::part(app-icon-img),
  bottom-header::part(app-icon-img) {
    width: 5rem;
    height: 5rem;
  }

  header-hero::part(app-icon),
  bottom-header::part(app-icon) {
    --app-icon-border-radius: 1rem;
  }

  /* header-hero .section-content .app-title  {
        font-size: 20px;
    } */

  header-hero {
    --app-title-font: 400 20px Rubik, "system-ui";
  }

   
}

@media only screen and (max-width: 1068px) {
  .section-content,
  header-hero::part(container) {
    width: 692px;
  }

  header-hero::part(container) {
    padding-block: 4rem;
    
}

  header-hero::part(app-icon) {
    margin-bottom: 1rem;
  }

  feature-tile {
    --tile-hardware-wrapper-width: 75%;
    --tile-hardware-wrapper-top: 2rem;
    height: 31rem;
  }


}

@media only screen and (max-width: 737px) {
  .section-content,
  header-hero::part(container) {
    width: 90%;
   
  }

  feature-tile {
   
    height: 35rem;
  }
}

@media only screen and (max-width: 374px) {
  .section-content,
  header-hero::part(container) {
    width: 95%;
  }
}

@media only screen and (min-width: 1441px) {
}
